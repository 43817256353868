@import-normalize;

@font-face {
  font-family: 'Oleo Script Swash Caps Regular';
  src: local('Oleo Script Swash Caps Regular'),
    url('./assets/oleo-script-swash-caps-regular.ttf') format('truetype');
}

body {
  background-color: #000;
  font-family: -apple-system, BlinkMacSystemFont, 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, monospace;
}
